<template>
  <el-dialog
    class="small-padding"
    :title="$t('developerDataAuthority.developerDataAuthority')"
    :visible.sync="dialogVisible"
    width="90%"
    top="5vh">
    <div style="width: 39%;float: left;">
      <vm-table ref="vmTable1" v-loading="loading1" :filter.sync="search1" url="developerDataAuthorities/list1">
        <template slot="adSearch">
          <vm-search :label="$t('maintWorkOrder.filter')">
            <el-input v-model.trim="search1.filter" :placeholder="$t('common.search')" clearable></el-input>
          </vm-search>
        </template>
        <table-column prop="no" :label="$t('developerDataAuthority.useUnitNo')"></table-column>
        <table-column prop="useUnitName" :label="$t('developerDataAuthority.useUnitName')"></table-column>
        <table-column prop="address" :label="$t('elevator.address')"></table-column>
        <table-column prop="person" :label="$t('elevator.person')"></table-column>
        <table-column :label="$t('common.operation')" :width="90" align="center" :tooltip="false">
          <template #default="scope">
            <el-button type="danger" @click="handleDelete(scope.row)">{{ $t("common.delete") }}</el-button>
          </template>
        </table-column>
      </vm-table>
    </div>
    <div style="width: 59%;float: left;margin-left: 2%">
      <vm-table
        ref="vmTable2"
        v-loading="loading2"
        :filter.sync="search2"
        url="developerDataAuthorities/list2"
        multi-selection="true"
        @row-click="handleRowClick"
        @selection-change="handleMultiSelectChange">
        <template slot="adSearch">
          <vm-search :label="$t('maintWorkOrder.filter')">
            <el-input v-model.trim="search2.filter" :placeholder="$t('common.search')" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('elevator.maintenanced', '维保企业')">
            <el-input v-model.trim="search2.maintComName" :placeholder="$t('common.search')" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('elevator.createComName', '制造企业')">
            <el-input v-model.trim="search2.createComName" :placeholder="$t('common.search')" clearable></el-input>
          </vm-search>
        </template>
        <template slot="toolbar">
          <el-button class="addBtn" @click="batchAdd">
            {{ $l("common.edit", "批量新增") }}
          </el-button>
        </template>
        <table-column prop="no" :label="$t('developerDataAuthority.useUnitNo')"></table-column>
        <table-column prop="useUnitName" :label="$t('developerDataAuthority.useUnitName')"></table-column>
        <table-column prop="address" :label="$t('elevator.address')" width="280"></table-column>
        <etable-column prop="person" :label="$t('elevator.person')"></etable-column>
        <table-column :label="$t('common.operation')" :width="90" align="center" :tooltip="false">
          <template #default="scope">
            <el-button type="primary" @click="handleAdd(scope.row)">{{ $t("common.add") }}</el-button>
          </template>
        </table-column>
      </vm-table>
    </div>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        multiSelect: [],
        DeveloperDataAuthority: {
          developerId: 0,
          useUnitId: 0,
        },
        search1: {
          filter: "",
          developerId: 0,
        },
        search2: {
          filter: "",
          developerId: 0,
        },
        loading1: false,
        loading2: false,
      };
    },
    methods: {
      open(developerId) {
        this.dialogVisible = true;
        this.DeveloperDataAuthority.developerId = developerId;
        if (this.DeveloperDataAuthority.developerId > 0) {
          this.getList1(1);
          this.getList2(1);
        }
      },
      getList1(pageNum) {
        this.loading1 = false;
        this.search1.developerId = this.DeveloperDataAuthority.developerId;
        console.log(this.search1.developerId);
        this.$nextTick(() => {
          this.$refs.vmTable1.getList(pageNum);
        });
      },
      getList2(pageNum) {
        this.loading2 = false;
        this.search2.developerId = this.DeveloperDataAuthority.developerId;
        console.log(this.search2.developerId);
        this.$nextTick(() => {
          this.$refs.vmTable2.getList(pageNum);
        });
      },
      handleRowClick(row, column) {
        if (column && column.label === this.$t("common.operation")) {
          return;
        }
        this.$refs.vmTable2.$refs.elTable.toggleRowSelection(row);
      },
      handleMultiSelectChange(val) {
        this.multiSelect = val;
      },
      batchAdd() {
        if (this.multiSelect.length !== 0) {
          let params = {
            developerId: this.DeveloperDataAuthority.developerId,
            useUnitIds: this.multiSelect.map(item => item.id),
          };
          this.$api.save("developerDataAuthorities/batch", params).then(res => {
            this.$message.success(this.$t("common.tip.addSuccess"));
            this.getList1(1);
            this.getList2(1);
          }).catch(() => {
            this.$message.error(this.$t("common.tip.addError"));
          });
        } else {
          this.$message.error("请至少选择一项");
        }
      },
      handleAdd(row) {
        let params = {
          developerId: this.DeveloperDataAuthority.developerId,
          useUnitId: row.id,
        };
        this.$confirm(this.$t("common.add") + " " + this.$t("useUnit.no") + " " + row.no + "，" + this.$t("common.isAdd") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.save("developerDataAuthorities", params).then(res => {
            this.getList1(1);
            this.getList2(1);
            this.$message.success(this.$t("common.tip.addSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.addError"));
          });
        });
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$t("useUnit.no") + " " + row.no + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById("developerDataAuthorities", row.id).then(res => {
            this.getList1(1);
            this.getList2(1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
